<template>
  <a-layout-header >
    <a-row type="flex">
    <a-col :span="6"> 
      <h1>
        <a id="logo" href="/">
          <img alt="logo" src="https://aliyuncdn.antdv.com/logo.png" />
          好工具
          </a>
      </h1>
    </a-col>
    <a-col :span="18">
      <a-menu v-model:selectedKeys="current" mode="horizontal" >
        <a-menu-item key="home">
          <template #icon>
            <home-outlined />
          </template>
          <router-link to="/">首页</router-link>
        </a-menu-item>
        <a-menu-item key="encode">
          <template #icon>
            <function-outlined />
          </template>
          <router-link to="/encode/base64">编码/加密</router-link>
        </a-menu-item>
        <a-menu-item key="json">
          <template #icon>
            <tool-outlined />
          </template>
          <router-link to="/json/csv">json工具</router-link>
        </a-menu-item>
      </a-menu>
    </a-col>
  </a-row>
  </a-layout-header>
  

</template>
  <script>
import { defineComponent, ref } from "vue";
import { FunctionOutlined,HomeOutlined,ToolOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    FunctionOutlined,
    HomeOutlined,
    ToolOutlined
  },

  setup() {
    const current = ref(["home"]);
    return {
      current,
    };
  },
});
</script>
  
<style scoped>
  .ant-layout-header{
    color: rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
  }
    #logo {
      height: 64px;
    }
    #logo img {
      height:32px;
    }
</style>