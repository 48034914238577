const routes = [
    {
        name: '',
        path: '/',
        component: () => import('@/pages/home')
    },
    {
        name: 'encode',
        path: '/encode',
        component: () => import('@/pages/encode'),
        children:[
            {
                name: 'base64',
                path: '/encode/base64',
                component:()=>import('@/pages/encode/base64.vue')
            },
            {
                name: 'utf8',
                path: '/encode/utf8',
                component:()=>import('@/pages/encode/utf8.vue')
            },
            {
                name: 'url',
                path: '/encode/url',
                component:()=>import('@/pages/encode/url.vue')
            },
            {
                name: 'md5',
                path: '/encode/md5',
                component:()=>import('@/pages/encode/md5.vue')
            }
        ]
    },
    {
        name: 'json',
        path: '/json',
        component: () => import('@/pages/json'),
        children:[
            {
                name: 'csv',
                path: '/json/csv',
                component:()=>import('@/pages/json/csv.vue')
            },
            {
                name: 'excel',
                path: '/json/excel',
                component:()=>import('@/pages/json/excel.vue')
            }
        ]
    }
    
];

export default routes