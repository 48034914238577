<template>
  <NavMenu />
  <router-view></router-view>
  <FooterVue></FooterVue>
</template>
<script>
import NavMenu from "./components/NavMenu.vue";
import FooterVue from "./components/Footer.vue";
export default {
  components: {
    NavMenu: NavMenu,
    FooterVue: FooterVue,
  },
};
</script>