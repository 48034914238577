<template>
  <a-layout-footer>
    <p>
      Copyright © 2022 小工集-实用工具百科. All Rights Reserved.<a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        >粤ICP备2023006910号</a
      >
    </p>
  </a-layout-footer>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    return {};
  },
});
</script>
    
<style scoped>
.ant-layout-footer {
  background-color: rgb(255, 255, 255);
  line-height: 1.5;
  text-align: center;
}
</style>